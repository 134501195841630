/*
* Override Bootstrap Variables here.
* For a list of variables to override see: https://github.com/twbs/bootstrap/blob/v4.6.1/scss/_variables.scss
*/

$green: #77a222;
$purple: #b6388e;

$pink: #ff219b;

$headings-font-family: "Futura PT Bold", var(--font-family-sans-serif);

$theme-colors: (
  'primary': $green,
  'secondary': #efece8,
  'light': #848484,
  'info': #f3f4ef,
  'freshstart': $purple,
  'made': $pink,
);



