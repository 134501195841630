@import 'variables';

html,
body {
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
}
